/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import MetaShape from '../shapes/MetaShape';
import Pagination, { ReadableMetaDescription } from './Pagination';
import Loading from './Loading';
import RefetchingDescription from './RefetchingDescription';

export default function ItemsList({
  body,
  meta,
  header,
  mobileBody,
  isLoading,
  isRefetching,
}) {
  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl">

      {/* Index page list (only on smallest breakpoint) */}
      {mobileBody}

      {/* Index page table (small breakpoint and up) */}
      {
          isLoading
            ? (<Loading forIndex />)
            : (
              <div className={classNames(mobileBody ? 'hidden sm:block' : null, 'mt-8')}>
                <div className="my-2">
                  {
                    isRefetching
                      ? (<RefetchingDescription />)
                      : (<ReadableMetaDescription meta={meta} />)
                  }
                </div>
                <div className="overflow-hidden rounded border border-gray-300">
                  <table className="min-w-full rounded-sm">
                    {header}
                    {body}
                  </table>
                  <Pagination meta={meta} />
                </div>
              </div>
            )
        }
    </div>
  );
}

ItemsList.propTypes = {
  body: PropTypes.element.isRequired,
  meta: MetaShape,
  header: PropTypes.element.isRequired,
  isLoading: PropTypes.bool.isRequired,
  mobileBody: PropTypes.element,
  isRefetching: PropTypes.bool,
};

ItemsList.defaultProps = {
  mobileBody: null,
  meta: null,
  isRefetching: null,
};
