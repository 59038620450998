/* eslint-disable max-len */
import { Dialog, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  ForwardIcon,
  SignalIcon,
  LinkIcon,
  // HomeIcon,
  UserIcon,
  XMarkIcon,
  // ClockIcon,
  RocketLaunchIcon,
  ShareIcon,
  // RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useState } from 'react';
import { GoWorkflow } from 'react-icons/go';
import { Link, useLocation } from 'react-router-dom';
import useAuth from '../../context/AuthContext';
import { AxiosContext } from '../../context/AxiosContext';
import useMe from '../../context/CurrentUserContext';
import { userAvatar } from '../../helpers/UserHelpers';
import useClient from '../../hooks/useClient';
import ChangePassword from '../../screens/Auth/ChangePassword';
import Modal from '../Modal';

const navigation = [
  // { name: 'Dashboard', icon: HomeIcon, to: '/dash' },
  { name: 'Connections', icon: LinkIcon, to: '/connections' },
  { name: 'Threads', icon: ShareIcon, to: '/threads' },
  { name: 'Events', icon: SignalIcon, to: '/events' },
  { name: 'Workflows', icon: GoWorkflow, to: '/workflows' },
  { name: 'Actions', icon: ForwardIcon, to: '/actions' },
  { name: 'Requests', icon: RocketLaunchIcon, to: '/requests' },
];

export default function SideBar({ children }) {
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

  const { currentUser, currentEntity } = useMe();
  const { clearTokens, persistAccessToken } = useAuth();
  const { pathname } = useLocation();

  const accountClient = useClient('accounts');
  const { data: accounts } = accountClient.list();

  const queryClient = useQueryClient();
  const { privateAxios } = useContext(AxiosContext);
  const switchAccount = async (account) => {
    const response = await privateAxios.post(`accounts/${account.id}/switch`, {});
    return response.data;
  };

  const { mutate } = useMutation({
    mutationFn: switchAccount,
    onSuccess: (r) => {
      persistAccessToken(r, () => queryClient.clear());
    },
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const accountsList = (accounts || []).map((account) => ({
    name: account.entity?.name,
    icon: UserIcon,
    entity: account.entity,
    onClick: () => mutate(account),
  }));
  const userNavigation = [
    { name: 'Change Password', icon: UserIcon, onClick: () => setShowPasswordChangeModal(true) },
    { name: 'Sign out', icon: ArrowRightOnRectangleIcon, onClick: clearTokens },
  ];

  return (
    <div>
      <Modal open={showPasswordChangeModal} onClose={() => setShowPasswordChangeModal(false)}>
        <ChangePassword onClose={() => setShowPasswordChangeModal(false)} />
      </Modal>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>
          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-100 px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-6 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=pink&shade=500"
                      alt="PartyBus"
                    />
                    <h1 className="ml-2 text-gray-600 font-semibold text-xl">Party</h1>
                    <h1 className="text-gray-600 text-xl">Bus</h1>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.to}
                                className={classNames(
                                  pathname.slice(0, item.to.length) === item.to
                                    ? 'bg-gray-200 text-gray-600'
                                    : 'text-gray-600 hover:text-gray-600 hover:bg-gray-100',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                )}
                              >
                                <item.icon
                                  className="h-4 w-4 shrink-0 text-gray-600"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-600">Your accounts</div>
                        <ul className="-mx-2 mt-2 space-y-1">
                          {accountsList.map((account) => (
                            <li key={account.name}>
                              <button
                                type="button"
                                onClick={account.onClick}
                                className={classNames(
                                  account.entity.id === currentEntity.id
                                    ? 'bg-gray-200 text-gray-600'
                                    : 'text-gray-600 hover:text-gray-600 hover:bg-gray-200',
                                  'group w-full flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                )}
                              >
                                <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-400 bg-gray-200 text-[0.625rem] font-medium text-gray-600">
                                  {account.name.slice(0, 1)}
                                </span>
                                <span className="truncate">{account.name}</span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </li>
                      {/* <li className="mt-auto">
                        <a
                          href="#"
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-600 hover:bg-gray-100 hover:text-gray-600"
                        >
                          <Cog6ToothIcon
                            className="h-6 w-6 shrink-0 text-gray-600 group-hover:text-gray-600"
                            aria-hidden="true"
                          />
                          Settings
                        </a>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col border border-gray-300">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-100 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=pink&shade=500"
              alt="PartyBus"
            />
            <h1 className="ml-2 text-gray-600 font-semibold text-2xl">Party</h1>
            <h1 className="text-gray-600 text-2xl">Bus</h1>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.to}
                        className={classNames(
                          pathname.slice(0, item.to.length) === item.to
                            ? 'text-gray-600 bg-gray-200'
                            : 'text-gray-600 hover:text-gray-600 hover:bg-gray-100',
                          'group flex gap-x-3 rounded-md p-1.5 text-sm leading-6 font-semibold',
                        )}
                      >
                        <item.icon
                          className="h-4 w-4 mt-1 shrink-0 text-gray-600"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-600">Your accounts</div>
                <ul className="-mx-2 mt-2 space-y-1">
                  {accountsList.map((account) => (
                    <li key={account.name}>
                      <button
                        type="button"
                        onClick={account.onClick}
                        className={classNames(
                          account.entity.id === currentEntity.id
                            ? 'bg-gray-200 text-gray-600'
                            : 'text-gray-600 hover:text-gray-600 hover:bg-gray-200',
                          'group w-full flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                        )}
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-400 bg-gray-200 text-[0.625rem] font-medium text-gray-600">
                          {account.name.slice(0, 1)}
                        </span>
                        <span className="truncate">{account.name}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
              {/* <li className="mt-auto">
                <a
                  href="#"
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-600 hover:bg-gray-100 hover:text-gray-600"
                >
                  <Cog6ToothIcon
                    className="h-6 w-6 shrink-0 text-gray-600 group-hover:text-gray-600"
                    aria-hidden="true"
                  />
                  Settings
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-60 flex h-screen flex-col">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-300 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          {/* <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" /> */}

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end">
            {/* <form className="relative flex flex-1" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                type="search"
                name="search"
              />
            </form> */}
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              {/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button> */}

              {/* Separator */}
              {/* <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" /> */}

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  <div className="flex items-center md:ml-12">
                    <div className=" flex justify-center items-center flex-row">
                      {userAvatar(currentUser)}
                      {' '}
                      <span className="hidden md:block">
                        <div className="ml-2 grid justify-items-start">
                          <div className="text-md leading-4 font-semibold text-gray-800">{currentUser.name}</div>
                          <div className="flex font-normal text-xs leading-3 text-gray-600 mt-1">
                            {currentUser.email}
                            <ChevronDownIcon className="m-0.5 ml-1 h-2 w-2 text-gray-600" />
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="divide-y divide-gray-100 absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <div>
                            <button
                              type="button"
                              onClick={item.onClick}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left',
                              )}
                            >
                              {item.name}
                            </button>
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        {/* <main className="py-10"> */}
        <div className="px-4 sm:px-6 lg:px-8 bg-gray-50 py-10 flex-1">
          {children}
        </div>
        {/* </main> */}
      </div>
    </div>
  );
}

SideBar.propTypes = {
  children: PropTypes.node.isRequired,
};
