/* eslint-disable max-len */
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlusCircleIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { toSearchParams, fromSearchParams } from '../helpers/ObjectHelpers';
import useClient from './useClient';

export default function useIndexFetchClient(clientName, options = {}) {
  const navigate = useNavigate();

  // Index Page Filter State
  const [filterOpen, setFilterOpen] = useState(false);

  // Index Page Data Fetching
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestFailed, setRequestFailed] = useState(false);
  const onError = () => setRequestFailed(true);

  const optionSearchParams = options.searchParams || {};
  const optionLatentSearchParams = options.latentSearchParams || {};
  // this needs to be forced to realize change
  const search = Object.fromEntries([
    ...Object.entries({ ...optionSearchParams, ...optionLatentSearchParams }),
    ...searchParams,
  ]);

  const {
    data,
    meta,
    isLoading,
    isRefetching,
  } = useClient(clientName).list(search, { onError });

  const urlParamsObj = Object.fromEntries(Array.from(searchParams));
  const defaultValues = fromSearchParams(searchParams);
  const filterForm = useForm({ defaultValues });

  const onChangeFilter = (filter) => {
    setFilterOpen(false);
    setSearchParams(toSearchParams(filter));
  };
  const onSubmit = filterForm.handleSubmit(onChangeFilter);

  return {
    data,
    filterForm,
    filterOpen,
    isLoading,
    isRefetching,
    initialLatentSearchParams: optionLatentSearchParams,
    meta,
    navigate,
    onError,
    onSubmit,
    setFilterOpen,
    requestFailed,
    urlParamsObj,
  };
}

const defaultMergeFunction = (data, config) => {
  const { startingData, indexItemForeignKeyName, mergeAsKey } = config;
  return startingData.map((indexDataItem) => {
    const dataToBeMerged = (data || []).find((i) => indexDataItem[indexItemForeignKeyName] === i.id);
    return { ...indexDataItem, [mergeAsKey]: dataToBeMerged };
  });
};

export function useFetchAndMergeAuxData(clientName, config) {
  const { filter = {}, requestConfig = {} } = config;
  const { data, isLoading } = useClient(clientName).list(filter, requestConfig);
  const mergeFunction = config.mergeFunction || defaultMergeFunction;
  const mergedData = mergeFunction(data, config);
  return { data: mergedData, isLoading };
}

export function useIndexButtons(resourceName, indexClient) {
  return [
    { label: 'Filter', icon: AdjustmentsVerticalIcon, onClick: () => indexClient.setFilterOpen(true) },
    { label: `New ${resourceName}`, icon: PlusCircleIcon, onClick: () => indexClient.navigate('new') },
  ];
}
