import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LinkButton from '../../../../../../components/buttons/LinkButton';
import { formatDateTime } from '../../../../../../helpers/DateHelpers';
import LogShape from '../../../../../../shapes/LogShape';
import { byInsertedAt } from '../../../../../../helpers/LogHelpers';

const responseColor = ({ http_status: statusCode, record_type: recordType }) => {
  let statusColor;
  if (statusCode >= 200 && statusCode < 299) statusColor = { bg: 'bg-teal-50', border: 'border-teal-500', text: 'text-teal-500' };
  if (statusCode >= 400 && statusCode < 499) statusColor = { bg: 'bg-yellow-50', border: 'border-yellow-500', text: 'text-yellow-500' };
  if (statusCode >= 500 || recordType === 'exception') statusColor = { bg: 'bg-red-50', border: 'border-red-500', text: 'text-red-500' };
  return statusColor;
};

function formatUrl(url) {
  if (!url) return '';

  const parsed = new URL(url);

  return parsed.pathname;
}

export default function RequestListModalBody({ logs, onClose, setCurrentLog }) {
  const sortedLogs = logs.sort(byInsertedAt);
  return (
    <div className="bg-white">
      <div className="px-4 pb-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Log Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Request body and response data for this action request.</p>
      </div>
      <div className="mx-4 mt-2 rounded border border-gray-300">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                URL
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Response Code
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Timestamp
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-300 bg-white">
            {sortedLogs.map((log) => (
              <tr key={log.id}>
                <td className="px-3 py-4 text-sm text-gray-500 flex">
                  <p className="font-medium text-indigo-700 mr-1">
                    {log.body.method}
                  </p>
                  {formatUrl(log.body.url)}
                </td>
                <td className={classNames(
                  `text-${responseColor(log.body)}-600`,
                  'whitespace-nowrap px-3 py-4 text-sm font-medium rounded-sm',
                )}
                >
                  <span className={`px-1 py-0.5 rounded border ${responseColor(log.body)?.border} ${responseColor(log.body)?.text}`}>
                    {log.body.http_status || log.body.record_type}
                  </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {formatDateTime(log.inserted_at)}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <LinkButton buttonText="Details" onClick={() => setCurrentLog(log)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-6">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Back
        </button>
      </div>
    </div>
  );
}

RequestListModalBody.propTypes = {
  logs: PropTypes.arrayOf(LogShape).isRequired,
  setCurrentLog: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
