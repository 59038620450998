import React from 'react';
import Input from '../../../../../components/forms/Input';
import FormShape from '../../../../../shapes/FormShape';

export default function BearerSubForm({ form }) {
  return (
    <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
      <div className="lg:col-span-1">
        <Input
          // displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          // defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          form={form}
          label="Token"
          name="token"
          placeholder="Token..."
        />
      </div>
    </div>
  );
}

BearerSubForm.propTypes = {
  form: FormShape.isRequired,
};
