import React from 'react';
import ActionShape from '../../../../../shapes/ActionShape';
import LinkButton from '../../../../../components/buttons/LinkButton';

export default function ActionNotAttempted({ action }) {
  return (
    <div className="border rounded bg-gray-100 border-gray-300 w-full p-2 text-gray-400">
      <h4 className="text-gray-700 text-sm mt-0.5">{action.name}</h4>
      <p className="text-xs mt-0.5">Not yet attempted...</p>
      <div className="flex justify-end border-t border-gray-300 mt-2 pt-1">
        <LinkButton className="text-xs mt-0.5" buttonText="View Action" path={`/actions/${action.id}`} />
      </div>
    </div>
  );
}

ActionNotAttempted.propTypes = {
  action: ActionShape.isRequired,
};
