import React from 'react';
import PropTypes from 'prop-types';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/layout/PageHeader';
import FormShape from '../../../shapes/FormShape';
import Footer from '../../../components/forms/Footer';
import SectionCard from '../../../components/layout/ShowPage/SectionCard';
import Input from '../../../components/forms/InputBase';
import BodyWrapper from '../../../components/layout/ShowPage/BodyWrapper';

export default function WorkflowCloneForm({
  form,
  onSubmit,
  isSubmitting,
}) {
  const navigate = useNavigate();
  return (
    <form onSubmit={onSubmit}>
      <Header
        name="Workflow Clone Form"
        description="Create a workflow by starting with a template to clone."
        icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <BodyWrapper>
        <SectionCard title="Part 1" subTitle="Start by giving this new workflow a name.">
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 2xl:grid-cols-6">
            <div className="2xl:col-span-3">
              <Input
                form={form}
                label="Workflow Name"
                name="name"
                required
                placeholder="Workflow Name..."
              />
            </div>
          </div>
          <Footer
            backButtonText="Back to Workflow Index Page"
            onBackButtonClick={() => navigate(-1)}
            submitButtonText="Submit"
            submitButtonDisabled={isSubmitting}
            submitButtonType="submit"
          />
        </SectionCard>
      </BodyWrapper>
    </form>
  );
}

WorkflowCloneForm.propTypes = {
  form: FormShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
