/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BiFilterAlt } from 'react-icons/bi';
import LinkButton from '../../../../components/buttons/LinkButton';
import UrlPreview from '../../../../components/UrlPreview';
import useMe from '../../../../context/CurrentUserContext';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import ActionShape from '../../../../shapes/ActionShape';

export default function ActionsList({
  templateCallbackActions,
  templateWorkflowActions,
  clonedWorkflowActions,
  setCurrentActionTemplate,
  currentActionTemplate,
}) {
  const { connections } = useMe();
  return (
    <ul>
      {
        templateCallbackActions.map((action, activityItemIdx) => {
          const clonedAction = clonedWorkflowActions?.find((a) => a.parent_id === action.id);

          // eslint-disable-next-line max-len
          const destinationConnection = connections.find((c) => c.id === action.destination_connection_id);
          const sourceConnection = connections.find((c) => c.id === action.source_connection_id);
          // eslint-disable-next-line max-len
          const currentCallbackOptions = templateWorkflowActions.filter((a) => a.topic === action.after_action?.topic);
          return (
            <li key={action.id} className="relative pt-3">
              <div className="flex gap-x-4">
                <div
                  className={classNames(
                    activityItemIdx === templateCallbackActions.length - 1 ? 'h-6' : '-bottom-6',
                    'absolute left-0 top-0 flex w-12 justify-center border-l ml-6 border-gray-300',
                  )}
                />
                <div className="flex">
                  <div
                    className={classNames(
                      action.matcher !== 'true' ? 'w-2' : 'w-8',
                      'h-3.5 border-b border-l border-gray-300 ml-6',
                    )}
                  />
                  {
                    action.matcher !== 'true'
                      ? (
                        <>
                          <div className="w-4 h-4 border mt-1.5 border-gray-300 rounded flex items-center justify-center">
                            <BiFilterAlt className="text-indigo-400 h-3 w-3" />
                          </div>
                          <div className="w-2 h-3.5 border-b border-gray-300" />
                        </>
                      )
                      : null
                  }
                  <div className="border rounded border-gray-300 w-full p-2">
                    <span className="flex justify-between">
                      <p className={classNames(
                        clonedAction ? 'text-gray-600' : 'text-gray-300',
                        'text-sm',
                      )}
                      >
                        {action.name}
                      </p>
                      {
                        !clonedAction
                          ? (
                            <LinkButton
                              className="ml-8 border px-1 rounded border-indigo-500 hover:bg-indigo-100 text-xs"
                              buttonText="Clone"
                              onClick={() => {
                                if (!currentActionTemplate) setCurrentActionTemplate(action);
                              }}
                            />
                          )
                          : null
                      }
                    </span>
                    {
                      clonedAction
                        ? (
                          <>
                            <div className="text-xs mt-1 flex">
                              <span className="mr-1">
                                {connectionAvatar(destinationConnection, { size: 'xxs' })}
                              </span>
                              <UrlPreview
                                method={action.configuration.method}
                                url={action.configuration.url}
                                connection={destinationConnection}
                              />
                            </div>
                            <div className="flex text-xs text-gray-600 mt-2">
                              <p className="w-24">Event Source:</p>
                              {sourceConnection?.name || clonedAction.source_connection_id}
                            </div>
                            <div className="flex text-xs text-gray-600 mt-1">
                              <p className="w-24">Destination:</p>
                              {destinationConnection?.name || clonedAction.destination_connection_id}
                            </div>
                            {
                              action.after_action?.topic
                                ? (
                                  <div className="flex text-xs text-gray-600 mt-1">
                                    <p className="w-24">Callback Event:</p>
                                    {action.after_action?.topic}
                                  </div>
                                )
                                : null
                            }
                          </>
                        )
                        : null
                    }

                  </div>
                </div>
              </div>
              {
              currentCallbackOptions.length
                ? (
                  <div className="ml-12">
                    <ActionsList
                      templateCallbackActions={currentCallbackOptions}
                      templateWorkflowActions={templateWorkflowActions}
                      setCurrentActionTemplate={setCurrentActionTemplate}
                      currentActionTemplate={currentActionTemplate}
                      clonedWorkflowActions={clonedWorkflowActions}
                    />
                  </div>
                )
                : null
              }
            </li>
          );
        })
}
    </ul>
  );
}

ActionsList.propTypes = {
  templateCallbackActions: PropTypes.arrayOf(ActionShape).isRequired,
  templateWorkflowActions: PropTypes.arrayOf(ActionShape).isRequired,
  clonedWorkflowActions: PropTypes.arrayOf(ActionShape),
  setCurrentActionTemplate: PropTypes.func.isRequired,
  currentActionTemplate: ActionShape,
};

ActionsList.defaultProps = {
  clonedWorkflowActions: [],
  currentActionTemplate: null,
};
