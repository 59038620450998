import React from 'react';
import Input from '../../../../../components/forms/Input';
import FormShape from '../../../../../shapes/FormShape';

export default function OAuthTwoSubForm({ form }) {
  const fields = [
    // { label: 'Auth URL', helper_text: '', name: 'auth_url' },
    { label: 'Client ID', helper_text: '', name: 'client_id' },
    { label: 'Client Secret', helper_text: '', name: 'client_secret' },
    { label: 'Scope', helper_text: '', name: 'scope' },
    // { label: 'Auth Parameter', helper_text: '', name: 'auth_parameter' },
  ];
  return (
    <div className="mt-2">
      {
        fields.map((f) => (
          <div className="mb-4" key={f.name}>
            <Input
              // displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
              // defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
              form={form}
              label={f.label}
              name={`api.authentication.${f.name}`}
              placeholder="Auth URL..."
            />
          </div>
        ))
      }
    </div>
  );
}

OAuthTwoSubForm.propTypes = {
  form: FormShape.isRequired,
};
