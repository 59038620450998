/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import { CommandLineIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../components/forms/Footer';
import Input from '../../../components/forms/InputBase';
import Header from '../../../components/layout/PageHeader';
import FormShape from '../../../shapes/FormShape';
import PageWrapper from '../../../components/layout/PageWrapper';
import SectionCard from '../../../components/layout/ShowPage/SectionCard';
import BodyWrapper from '../../../components/layout/ShowPage/BodyWrapper';
import JSONInput from '../../../components/forms/JSONInput';

export default function Form({
  displayLayoutInput,
  formTitle,
  formSubTitle,
  isSubmitting,
  form,
  onSubmit,
  submitButtonText,
}) {
  const navigate = useNavigate();

  let nextDisabled;
  return (
    <PageWrapper>
      <Header
        name={formTitle}
        description={formSubTitle}
        icon={<CommandLineIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <form onSubmit={onSubmit}>
        <BodyWrapper>
          <SectionCard>
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 2xl:grid-cols-6">
              <div className="2xl:col-span-3">
                <Input
                  form={form}
                  label="Workflow Name"
                  name="name"
                  required
                  placeholder="Workflow Name..."
                />
              </div>
              {
                displayLayoutInput
                  ? (
                    <div className="col-span-6">
                      <JSONInput
                        form={form}
                        label="Workflow Layout"
                        name="layout"
                      />
                    </div>
                  )
                  : null
              }
            </div>
            <Footer
              backButtonText="Back to Workflows"
              onBackButtonClick={() => navigate(-1)}
              submitButtonText={submitButtonText}
              submitButtonDisabled={nextDisabled || isSubmitting}
              submitButtonType="submit"
            />
          </SectionCard>
        </BodyWrapper>

      </form>
    </PageWrapper>
  );
}

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: FormShape.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  formSubTitle: PropTypes.string.isRequired,
  displayLayoutInput: PropTypes.bool.isRequired,
};
