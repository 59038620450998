import React from 'react';
import Input from '../../../../../components/forms/Input';
import FormShape from '../../../../../shapes/FormShape';

export default function ApiKeySubForm({ form }) {
  return (
    <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
      <div className="lg:col-span-1">
        <Input
          // displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          // defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          form={form}
          label="Header Key"
          name="key"
          placeholder="Header Key..."
        />
      </div>
      <div className="lg:col-span-1">
        <Input
          // displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          // defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          form={form}
          label="Header Value"
          name="value"
          placeholder="Header Value..."
        />
      </div>
    </div>
  );
}

ApiKeySubForm.propTypes = {
  form: FormShape.isRequired,
};
