import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { PencilIcon, TrashIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import Header from '../../../components/layout/PageHeader';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import ConnectionShape from '../../../shapes/ConnectionShape';
import JSONConfig from './Json';
import DeleteConfirm from '../../../components/DeleteConfirm';
import YamlCard from './YamlCard';
import RefreshConfirm from './RefreshConfirm';
import OAuthSuccessPreviewCard from './API/OAuthSuccessPreviewCard';
import History from './History';
import Tabs from './Tabs';
import InfoCard from './InfoCard';
import { formatDateTime } from '../../../helpers/DateHelpers';
import ApiCard from './API/ApiCard';
import ApiHistory from './API/ApiHistoryCard';
import WebhookTopicCard from './Webhook/TopicCard';
import WebhookEndpointCard from './Webhook/EndpointCard';
import WebhookSecurity from './Webhook/Security';

export default function View({
  connection, connectionTemplate, onRetry, onDestroy,
}) {
  const [modal, setModal] = useState(null);
  const [currentTab, setCurrentTab] = useState('General Info');
  const navigate = useNavigate();

  const buttons = [
    { label: 'Delete', icon: TrashIcon, onClick: () => setModal('delete') },
    // { label: 'Pause', icon: PlayPauseIcon, onClick: () => setShowPauseConfirm(true) },
    { label: 'Edit', icon: PencilIcon, onClick: () => navigate('edit') },
    { label: 'Refresh', icon: ArrowPathIcon, onClick: () => setModal('refresh') },
  ];

  return (
    <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
      <DeleteConfirm
        title="Delete Action"
        description="Are you sure you want to permanently delete this action?"
        open={modal === 'delete'}
        onClose={() => setModal(null)}
        onConfirm={onDestroy}
      />
      <RefreshConfirm
        title="Refresh Credentials"
        description="Are you sure you want to retry this connection's credentials?"
        open={modal === 'refresh'}
        onClose={() => setModal(null)}
        onConfirm={onRetry}
      />
      <Header
        description={`Added on ${formatDateTime(connection.inserted_at)}.`}
        name={connection.name}
        buttons={buttons}
        avatar={connectionAvatar(connection, { size: 'lg' })}
      />
      <div className="mt-8 max-w-3xl lg:max-w-7xl">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 ">
          <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
          {
              currentTab === 'General Info'
                ? (
                  <>
                    <InfoCard connection={connection} connectionTemplate={connectionTemplate} />
                    <History connection={connection} />
                  </>
                )
                : null
            }
          {
            currentTab === 'API Details'
              ? (
                <>
                  <ApiCard connection={connection} connectionTemplate={connectionTemplate} />
                  <ApiHistory connection={connection} />
                  <OAuthSuccessPreviewCard connection={connection} />
                </>

              )
              : null
          }
          {
            currentTab === 'Webhook Details'
              ? (
                <>
                  <WebhookEndpointCard connection={connection} />
                  <WebhookTopicCard connection={connection} />
                  <WebhookSecurity connection={connection} />
                </>
              )
              : null
          }
          {

              currentTab === 'Configuration'
                ? (
                  <>
                    <JSONConfig connection={connection} />
                    <YamlCard connection={connection} />
                  </>
                )
                : null
                }
          {/*
            <WebhookConfiguration connection={connection} />
            <ConnectionsCard connection={connection} />
            */}
        </div>
      </div>
    </div>

  );
}

View.propTypes = {
  connection: ConnectionShape.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};
