import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import ActionShape from '../../../../../shapes/ActionShape';
import LogShape from '../../../../../shapes/LogShape';
import LinkButton from '../../../../../components/buttons/LinkButton';
import MidDot from '../../../../../components/MidDot';
import useClient from '../../../../../hooks/useClient';
// import { formatDateTimeWithSeconds, formatDate } from '../../../../../helpers/DateHelpers';

export default function YetToSucceed({ action, actionLogs }) {
  const attempts = actionLogs.length;
  // eslint-disable-next-line radix
  const maxRetryCount = parseInt(action.retry_strategy?.max_attempts || 5);
  const maxAttemptsReached = attempts >= maxRetryCount;
  const eventId = actionLogs[0].records.event_id;

  const { data: jobs, isLoading: isLoadingJobs } = useClient('jobs').list(
    { records: { event_id: eventId }, page_size: 100 },
    { enabled: !!eventId },
  );

  const { isLoading: isSubmitting, mutate } = useClient('logs').post(
    actionLogs[0].id,
    'retry',
    {
      onSuccess: () => console.log('boom'),
      onError: (error) => console.log(error.response.data),
    },
  );

  return (
    <div className={classNames('border rounded border-gray-300 w-full p-2 flex text-gray-400', maxAttemptsReached ? 'bg-red-100' : 'bg-gray-100')}>
      <div className="text-xs mt-0.5">
        <div className="flex justify-between">
          <h4 className="text-gray-700 text-sm mt-0.5">
            {action.name}
          </h4>
          <span className="flex">
            {
              maxAttemptsReached
                ? (
                  <>
                    <Tooltip
                      id={`max${action.id}`}
                      content={(
                        <p>
                          Retried
                          {' '}
                          {attempts}
                          {' '}
                          times. Max attempts reached!
                        </p>
                      )}
                    />
                    <span data-tooltip-id={`max${action.id}`}>
                      <ExclamationCircleIcon className="text-red-400 h-4 w-4 hover:cursor-pointer" />
                    </span>
                  </>
                )
                : (
                  <>
                    <Tooltip
                      id={`in_prog${action.id}`}
                      content={(
                        <p>
                          Retried
                          {' '}
                          {attempts}
                          {' '}
                          times. Next retry at ...!
                        </p>
                    )}
                    />
                    <span data-tooltip-id={`in_prog${action.id}`}>
                      <svg fill="none" className="w-5 h-5 animate-spin" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <path
                          clipRule="evenodd"
                          d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                          fill="currentColor"
                          fillRule="evenodd"
                        />
                      </svg>
                    </span>
                  </>
                )
            }
          </span>
        </div>
        <div className="flex justify-end border-t border-gray-300 mt-2 pt-1">
          <LinkButton className="text-xs mt-0.5" buttonText="View Action" path={`/actions/${action.id}`} />
          <MidDot />
          <LinkButton className="text-xs mt-0.5" buttonText="Event Details" path={`/events/${eventId}`} />
          <MidDot />
          <LinkButton className="text-xs mt-0.5" buttonText="Retry Request" onClick={() => mutate()} />
        </div>
      </div>
    </div>
  );
}

YetToSucceed.propTypes = {
  action: ActionShape.isRequired,
  actionLogs: PropTypes.arrayOf(LogShape).isRequired,
};
