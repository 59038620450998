import React from 'react';
import PropTypes from 'prop-types';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { Tooltip } from 'react-tooltip';
import LinkButton from '../../../../../components/buttons/LinkButton';
import UrlPreview from '../../../../../components/UrlPreview';
import { connectionAvatar } from '../../../../../helpers/ConnectionHelpers';
import ActionShape from '../../../../../shapes/ActionShape';
import { formatDateTimeWithSeconds, formatDate } from '../../../../../helpers/DateHelpers';
import useMe from '../../../../../context/CurrentUserContext';
import LogShape from '../../../../../shapes/LogShape';
import MidDot from '../../../../../components/MidDot';

export default function Succeeded({ action, actionLogs }) {
  const { connections } = useMe();
  const destinationConnection = connections.find((c) => c.id === action.destination_connection_id);
  const eventId = actionLogs[0].records.event_id;
  const logId = actionLogs[0].id;

  return (
    <div className="bg-white border rounded border-gray-300 w-full p-2">
      <div className="flex justify-between">
        <h4 className="text-gray-700 text-sm mt-0.5">
          {action.name}
        </h4>
        <span className="flex">
          <Tooltip
            id={`tool${action.id}`}
            content={(
              <p>
                Succeeded at
                {' '}
                {formatDate(actionLogs[0].inserted_at)}
                {' '}
                {formatDateTimeWithSeconds(actionLogs[0].inserted_at)}
              </p>
          )}
          />
          <span data-tooltip-id={`tool${action.id}`}>
            <IoCheckmarkCircleOutline className="text-teal-500 h-4 w-4 hover:cursor-pointer" />
          </span>
        </span>
      </div>
      <div className="text-xs flex mt-1">
        <span className="mr-1">
          {connectionAvatar(destinationConnection, { size: 'xxs' })}
        </span>
        <UrlPreview
          method={action.configuration.method}
          url={action.configuration.url}
          connection={destinationConnection}
        />
      </div>
      <div className="flex justify-end border-t border-gray-300 mt-2 pt-1">
        <LinkButton className="text-xs mt-0.5" buttonText="View Action" path={`/actions/${action.id}`} />
        <MidDot />
        <LinkButton className="text-xs mt-0.5" buttonText="Event Details" path={`/events/${eventId}`} />
      </div>
    </div>
  );
}

Succeeded.propTypes = {
  action: ActionShape.isRequired,
  actionLogs: PropTypes.arrayOf(LogShape).isRequired,
};
