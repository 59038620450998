import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connectionAvatar } from '../../../../../../helpers/ConnectionHelpers';
import LogShape from '../../../../../../shapes/LogShape';
import useMe from '../../../../../../context/CurrentUserContext';
import ModalBody from '../../../../../Action/ShowPage/LogDetailModalBody';
import Modal from '../../../../../../components/Modal';
import LinkButton from '../../../../../../components/buttons/LinkButton';
import RequestListModalBody from './RequestListModalBody';

function logStatusColor(log) {
  const statusCode = log.body?.http_status;
  if (statusCode >= 200 && statusCode < 299) return 'bg-teal-50 text-teal-500 ring-teal-500';
  if (statusCode >= 400 && statusCode < 499) return 'bg-yellow-50 text-yellow-500 ring-yellow-500';
  if (statusCode >= 500) return 'bg-red-50 text-red-500 ring-red-500';
  return 'bg-gray-50 text-gray-500 ring-gray-500';
}

export default function WorkflowSubline({ matchedActionLog, index }) {
  const [displayRequest, setDisplayRequest] = useState(null);
  const [displayRequestList, setDisplayRequestList] = useState(false);
  const { actions, connections } = useMe();

  const action = actions.find((a) => a.id === matchedActionLog.records.action_id);
  const destinationConnection = connections.find((c) => c.id === action?.destination_connection_id);
  const processLog = matchedActionLog.requestLogs[0];

  // Look for at least 1 response code of 200 to determine resolution of an action
  const hasResolved = matchedActionLog.requestLogs.map((l) => l.status).includes('success');
  const borderColor = hasResolved ? 'border-teal-400' : 'border-red-400';

  return (
    <div key={matchedActionLog.id} className={classNames('border-2 rounded mb-2 p-2', borderColor)}>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={displayRequestList}
        onClose={() => setDisplayRequestList(false)}
      >
        <RequestListModalBody
          logs={matchedActionLog.requestLogs}
          onClose={() => setDisplayRequestList(false)}
          setCurrentLog={setDisplayRequest}
        />
      </Modal>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={!!displayRequest}
        onClose={() => setDisplayRequest(null)}
      >
        <ModalBody log={displayRequest} onClose={() => setDisplayRequest(null)} />
      </Modal>
      <span className="flex space-x-1 md:flex">
        <p>
          Action #
          {index + 1}
          :
          {' '}
        </p>
        <Link to={`/actions/${action?.id}`} className="text-indigo-700">
          {action?.name}
        </Link>
      </span>
      <span className="ml-4 flex space-x-1 md:flex">
        <p>Endpoint:</p>
        <span className="mt-0.5 mr-1">
          {connectionAvatar(destinationConnection, { size: 'xxs' })}
        </span>
        <div className="text-gray-500 flex space-x-1">
          <span className="hidden xl:block">
            {destinationConnection?.configuration?.url}
          </span>
          <p className="text-teal-600 font-semibold">
            {processLog.body.method}
          </p>
          <p>{processLog.body.url?.replace(destinationConnection?.configuration?.url, '')}</p>
          <p
            className={
              classNames(
                'max-h-4 hover:cursor-pointer inline-flex items-center rounded my-0.5 px-0.5 text-xs font-medium ring-1 ring-inset',
                logStatusColor(processLog),
              )
            }
          >
            {processLog.body.http_status}
          </p>
        </div>
      </span>
      <span className="ml-4 flex space-x-1 w-full">
        <p>Attempts:</p>
        <span className="flex">
          <LinkButton
            className="font-medium text-xs"
            onClick={() => setDisplayRequestList(true)}
            buttonText={`${matchedActionLog.requestLogs?.length} Attempt${matchedActionLog.requestLogs?.length === 1 ? '' : 's'}`}
          />
        </span>
      </span>
    </div>
  );
}

WorkflowSubline.propTypes = {
  matchedActionLog: LogShape.isRequired,
  index: PropTypes.number.isRequired,
};
