import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Loading from '../../../components/Loading';
import useMe from '../../../context/CurrentUserContext';
import Workflow from './Workflow';
import EventShape from '../../../shapes/EventShape';

export default function ThreadTree({ event, threadEvents, threadLogs, isLoadingLogs }) {
  const { workflows } = useMe();

  const threadActionLogs = threadLogs.filter((l) => l.topic === 'action.evaluated' && l.body.result === true);
  const threadWorkflowIds = _.uniq(threadActionLogs.map((l) => l.records.workflow_id));
  const threadWorkflows = workflows.filter((w) => threadWorkflowIds.includes(w.id));

  if (isLoadingLogs) {
    return (
      <tr className="w-full">
        <td colSpan={5}>
          <Loading className="flex items-center justify-center w-full h-12 my-4" />
        </td>
      </tr>
    );
  }
  return threadWorkflows.map((w) => (<Workflow key={w.id} workflow={w} threadLogs={threadLogs} />));
}

ThreadTree.propTypes = {
  event: EventShape.isRequired,
};
