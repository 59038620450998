import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import ConnectionShape from '../../../shapes/ConnectionShape';
import useMe from '../../../context/CurrentUserContext';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import LinkButton from '../../../components/buttons/LinkButton';

export default function SuccessPanel({ connection, preview }) {
  const { currentUser } = useMe();

  return (
    <div className="rounded-md border border-gray-300 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon aria-hidden="true" className="h-5 w-5 mt-0.5 text-green-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-md font-medium text-gray-800">Install complete!</h3>
          <div className="mt-2 text-sm text-green-700">

            <span className="text-sm text-gray-500 flex">
              You have successfully installed your
              <span className="mt-0.5 mx-1">
                {connectionAvatar(connection, { size: 'xxs' })}
              </span>
              {' '}
              <b className="mr-1">
                {connection?.name}
              </b>
              {' '}
              OAuth2 app!
            </span>
            <p className="text-xs text-gray-400 mt-1 mb-3">
              {connection?.description}
            </p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              {currentUser && connection?.id && !preview
                ? (
                  <div className="mt-5">
                    <LinkButton
                      className="flex space-x-1"
                      path={`/connections/${connection?.id}`}
                      buttonText={(
                        <>
                          <ChevronLeftIcon className="h-4 w-4 mt-0.5 text-indigo-700" />
                          <p className="text-indigo-700">
                            Back to Connection Detail Page
                          </p>
                        </>
                        )}
                    />
                  </div>
                )
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

SuccessPanel.propTypes = {
  connection: ConnectionShape,
  preview: PropTypes.bool,
};

SuccessPanel.defaultProps = {
  connection: null,
  preview: false,
};
