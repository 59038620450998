import React from 'react';
import { Link } from 'react-router-dom';
import LogShape from '../../../../../../shapes/LogShape';
import { formatId } from '../../../../../../helpers/RecordHelpers';
import ActionLine from './ActionLine';

export default function WorkflowSubline({ log }) {
  return (
    <span className="font-medium text-gray-500 ml-3">
      <span className="flex space-x-1 md:flex">
        <p>Evaluation: </p>
        <p className="bg-gray-100 border border-gray-300 rounded-sm text-xs p-0 m-0.5 px-1">
          {formatId(log.evaluation)}
        </p>
      </span>
      <span className="flex space-x-1 md:flex">
        <p>Workflow:</p>
        <Link to={`/workflows/${log.workflow?.id}`} className="text-indigo-700">
          {log.workflow?.name}
        </Link>
      </span>
      { log.matchedActions.map((l, i) => <ActionLine key={`ma${l.id}`} matchedActionLog={l} index={i} />) }
    </span>
  );
}

WorkflowSubline.propTypes = {
  log: LogShape.isRequired,
};
