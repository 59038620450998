/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import cronstrue from 'cronstrue';
import { ClockIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import useMe from '../../../../context/CurrentUserContext';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import LinkButton from '../../../../components/buttons/LinkButton';
import ActionsList from './ActionsList';
import UrlPreview from '../../../../components/UrlPreview';
import ActionShape from '../../../../shapes/ActionShape';
import LogShape from '../../../../shapes/LogShape';

export default function ScheduledWorkflowTrigger({ action, workflowActions, threadLogs }) {
  const { connections } = useMe();
  const destinationCxn = connections.find(({ id }) => id === action.destination_connection_id);
  const callbackActions = workflowActions.filter((a) => action.after_action?.topic === a.topic);
  const triggerLogs = threadLogs.filter((l) => l.topic === 'event.created'
    && l.body.topic === trigger.topic
    && l.body.connection_id === trigger.source_connection_id);

  return (
    <li key={action.id} className="w-fit">
      <div className={classNames(triggerLogs.length ? 'bg-white' : 'bg-gray-100', 'border border-gray-300 rounded p-2 text-xs w-fit')}>
        <span className="flex space-x-1 text-sm">
          <span className="text-gray-700 font-medium flex">
            <ClockIcon className="m-0.5 w-4 h-4 border border-gray-300 rounded text-indigo-700" />
          </span>
          <p className="font-normal text-gray-500 mr-1">
            Poll
            {' '}
            <LinkButton className="text-sm" buttonText={destinationCxn.name} path={`/connections/${destinationCxn.id}`} />
          </p>
          <p>{cronstrue.toString(action.schedule)}</p>
          <p className="font-normal text-gray-500 mr-1">for data.</p>
        </span>
        <div className="w-full mt-1 px-2">
          <div className="flex">
            <p className="mr-1">Action:</p>
            <LinkButton buttonText={action.name} className="text-xs mb-1" path={`/actions/${action.id}`} />
          </div>
          <div className="text-xs flex">
            <span className="mr-1">
              {connectionAvatar(destinationCxn, { size: 'xxs' })}
            </span>
            <UrlPreview
              method={action.configuration.method}
              url={action.configuration.url}
              connection={destinationCxn}
              extractConfig={{ event: false, function: false }}
            />
          </div>
        </div>
      </div>
      {
        callbackActions.length
          ? <ActionsList callbackActions={callbackActions} workflowActions={workflowActions} threadLogs={threadLogs} />
          : null
      }
    </li>
  );
}

ScheduledWorkflowTrigger.propTypes = {
  action: ActionShape.isRequired,
  workflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
  threadLogs: PropTypes.arrayOf(LogShape).isRequired,
};
